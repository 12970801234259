/* General */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

*,
p {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

p {
  color: rgb(85, 85, 85);
  margin: 0.5rem;
}

a,
.btn {
  transition: all 300ms ease;
  color: black;
  text-decoration: none;
}

a:hover,
.menu-links a:hover {
  color: grey;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: rgb(181, 181, 181);
}

.iconStyle {
  width: 2rem; 
  height: 2rem; 
}


/* Desktop Navigation */
nav,
.nav-links {
  display: flex;
}

nav {
  justify-content: space-around;
  align-items: center;
  height: 17vh;
}

.nav-links {
  gap: 2rem;
  list-style: none;
  font-size: 1.5rem;
}

.logo {
  font-size: 2rem;
  cursor: default;
}

/* Hamburger */

#hamburger-nav {
  display: none;
}

.hamburger-menu {
  position: relative;
  display: inline-block;
}

.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1.5rem;
  height: 24px;
  width: 30px;
  cursor: pointer;
}

.hamburger-icon span {
  width: 100%;
  height: 3px;
  background-color: black;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.menu-links {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  width: fit-content;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3 ease-in-out;
  padding: 0.7rem;
  border-radius: 0.7rem;
}

.menu-links a {
  display: block;
  padding: 10px;
  text-align: center;
  font-size: 1.5rem;
  color: black;
  text-decoration: none;
  transition: all 0.3 ease-in-out;
}

.menu-links li {
  list-style: none;
}

.menu-links.open {
  max-height: 300px;
  z-index: 1001;
}

.hamburger-icon.open span:first-child {
  transform: rotate(45deg) translate(10px, 5px);
}

.hamburger-icon.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-icon.open span:last-child {
  transform: rotate(-45deg) translate(10px, -5px);
}

/* Sections */
section {
  padding-top: 4vh;
  height: 96vh;
  margin: 0 10rem;
  box-sizing: border-box;
  min-height: fit-content;
}

.section-container {
  display: flex;
}

/* Profile Section */
#profile {
  display: flex;
  justify-content: center;
  gap: 5rem;
  height: 80vh;
}

.section__pic-container {
  display: flex;
  height: 400px;
  width: 400px;
  margin: auto 0;
}

.section__text {
  align-self: center;
  text-align: center;
}

.section__text p {
  font-weight: 600;
}

.section__text__p1 {
  text-align: center;
}

.section__text__p2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.title {
  font-size: 3rem;
  text-align: center;
}

#socials-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
}

/* Icons */
.icon,
.LinkedInIcon,
.ExpIcon,
.EducationIcon,
.GithubIcon {
  cursor: pointer;
  height: 2rem;
  text-align: center;
  gap: 0.5rem;
  transition: 0.5s;
  display: flex;
  align-items: center;
}

.icon i {
  display: flex;
  font-size: 2rem;
}

.icon span {
  font-size: larger;
  background: linear-gradient(to right, #ff2600 0%, #00d4d0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.icon:hover {
  color: #00d4d0;
  animation: jello-horizontal 0.6s both;
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

/* Buttons */
.btn-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
}

.btn {
  font-weight: 600;
  padding: 1rem;
  width: 8rem;
  border-radius: 2rem;
}

.btn-color-1,
.btn-color-2 {
  border: rgb(53, 53, 53) 0.1rem solid;
}

.btn-color-1:hover,
.btn-color-2:hover {
  cursor: pointer;
}

.btn-color-1,
.btn-color-2:hover {
  background: rgb(53, 53, 53);
  color: white;
}

.btn-color-1:hover {
  background: rgb(0, 0, 0);
}

.btn-color-2 {
  background: none;
}

.btn-color-2:hover {
  border: rgb(255, 255, 255) 0.1rem solid;
}

/* Download CV Button*/
.download-cv-btn {
  width: 125px;
  height: 45px;
  border-radius: 20px;
  border: none;
  box-shadow: 1px 1px rgba(107, 221, 215, 0.37);
  padding: 5px 10px;
  background: rgb(47, 93, 197);
  background: linear-gradient(
    160deg,
    rgba(47, 93, 197, 1) 0%,
    rgba(46, 86, 194, 1) 5%,
    rgba(47, 93, 197, 1) 11%,
    rgba(59, 190, 230, 1) 57%,
    rgba(0, 212, 255, 1) 71%
  );
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 505;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  filter: drop-shadow(0 0 10px rgba(59, 190, 230, 0.568));
  transition: 0.5s linear;
}

.download-cv-btn .mysvg {
  display: none;
}

.download-cv-btn:hover {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  transition: 0.5s linear;
}

.download-cv-btn:hover .texto {
  display: none;
}

.download-cv-btn:hover .mysvg {
  display: inline;
}

.download-cv-btn:hover::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3.5px solid transparent;
  border-top: 3.5px solid #fff;
  border-right: 3.5px solid #fff;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* About Section */
#about {
  position: relative;
}

.about-containers {
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.about-details-container {
  justify-content: center;
  flex-direction: column;
}

.about-containers,
.about-details-container {
  display: flex;
}

.about-pic {
  border-radius: 2rem;
  filter: grayscale(0%);

  &:hover {
    filter: grayscale(15%);
  }
}

.arrow {
  position: absolute;
  right: -5rem;
  bottom: 2.5rem;
}

.details-container {
  padding: 1.5rem;
  flex: 1;
  background: transparent;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  text-align: center;
  transform-style: preserve-3d;
  perspective: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-container {
  gap: 4rem;
  height: 80%;
}

/* Experience Section */
#experience {
  position: relative;
}

.experience-sub-title {
  color: black;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 2rem;
  text-decoration: underline;
}

.experience-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.article-container {
  display: flex;
  text-align: initial;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
}

article {
  display: flex;
  width: 10rem;
  justify-content: space-around;
  gap: 0.5rem;
  margin: 1rem;
  align-items: center;
}

article .icon {
  cursor: default;
}

/* Projects Section */
#projects {
  position: relative;
}

.color-container {
  border-color: rgb(163, 163, 163);
  background: rgb(250, 250, 250);
}

.project-img {
  border-radius: 2rem;
  width: 90%;
  height: 90%;
}

.project-title,
.project-text {
  margin: 1rem;
  color: black;
  transform: translateZ(30px);
}

.project-btn {
  color: black;
  border-color: rgb(163, 163, 163);
  transform: translateZ(30px);
}

/* Contact */
#contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.contact-info-upper-container {
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  border: rgb(53, 53, 53) 0.1rem solid;
  border-color: rgb(163, 163, 163);
  background: (250, 250, 250);
  margin: 2rem auto;
  padding: 0.5rem;
}

.contact-info-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem;
}

.contact-info-container p {
  font-size: larger;
}

.contact-icon {
  cursor: default;
}

.email-icon {
  height: 2.5rem;
}

/* Footer Section */
footer {
  height: 26vh;
  margin: 0 1rem;
}

footer p {
  text-align: center;
}

@media screen and (max-width: 1400px) {
  #profile {
    height: 83vh;
    margin-bottom: 6rem;
  }
  .about-containers {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1200px) {
  #desktop-nav {
    display: none;
  }
  #hamburger-nav {
    display: flex;
  }
  #experience,
  .experience-details-container {
    margin-top: 2rem;
  }
  #profile,
  .section-container {
    display: block;
  }
  .arrow {
    position: absolute;
    right: 3vw;
    bottom: 0vh;
  }
  section,
  .section-container {
    height: fit-content;
  }
  section {
    margin: 0 5%;
  }
  .section__pic-container {
    width: 275px;
    height: 275px;
    margin: 0 auto 2rem;
  }
  .about-containers {
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  #contact,
  footer {
    height: 40vh;
  }
  #profile {
    height: 83vh;
    margin-bottom: 0;
  }
  article {
    font-size: 1rem;
  }
  footer nav {
    height: fit-content;
    margin-bottom: 2rem;
  }
  .about-containers,
  .contact-info-upper-container,
  .btn-container {
    flex-wrap: wrap;
  }
  .contact-info-container {
    margin: 0;
  }
  .contact-info-container p,
  .nav-links li a {
    font-size: 1rem;
  }
  .details-container[data-tilt] {
    /* Prevents Tilt.js from applying transform */
    transform: none !important;
  }
  .experience-sub-title {
    font-size: 1.25rem;
  }
  .logo {
    font-size: 1.5rem;
  }
  .nav-links {
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
  }
  .section__pic-container {
    width: auto;
    height: 46vw;
    justify-content: center;
  }
  .section__text__p2 {
    font-size: 1.25rem;
  }
  .title {
    font-size: 2rem;
  }
  .text-container {
    text-align: justify;
  }
}

@media screen and (orientation: landscape) and (max-width: 600px) {
  #profile,
  #about,
  #experience,
  #projects,
  #contact,
  footer {
    transform: scale(100%);
    /* height: max-content; */
    /* width: max-content; */
  }
}
@media screen and (orientation: landscape) and (max-width: 1200px) {
  #profile,
  #about,
  #experience,
  #projects,
  #contact,
  footer {
    transform: scale(90%);
  }
}
@media screen and (orientation: landscape) and (max-width: 1400px) {
  #profile,
  #about,
  #experience,
  #projects,
  #contact,
  footer {
    margin-top: 25rem;
  }
}
@media screen and (orientation: landscape) and (min-width: 1400px) {
  #profile,
  #about,
  #experience,
  #projects,
  #contact,
  footer {
    margin-top: 8rem;
  }
  .experience-details-container {
    overflow: auto;
  }
}

/* Dark Mode Toggler */
.dark-mode-toggler {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
}

#darkmode-toggle {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -2px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  display: block;
  height: 32px;
  position: absolute;
  right: -10px;
  top: -8px;
  width: 75px;
  background: #ebebebc3;
  border: black 1px solid;
  border-radius: 21px;
  transition: all 0.3s ease 0s;
}

/* Toggle Circle */
label:after {
  content: "";
  width: 40px;
  height: 35px;
  position: absolute;
  top: -2px;
  left: -1px;
  background: linear-gradient(180deg, #242424);
  border-radius: 40px;
  transition: 0.3s;
  box-shadow: inset 0px 5px 10px rgba(0, 0, 0, 0.5);
}

input:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

label:active:after {
  width: 50px;
}

/* Sun and Moon Icon Styles */
label .sun-icon,
label .moon-icon {
  height: 25px;
  margin-bottom: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  transition: all 0.3s ease 0s;
}

label .sun-icon {
  left: 8px;
}

label .moon-icon {
  right: 7px;
}

/* Dark Mode Styles */
.dark-mode {
  background-color: #303030;
}

.dark-mode,
.dark-mode .menu-links {
  background-color: #252525;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}

.dark-mode .arrow {
  filter: invert(1);
  transform: scale(1.1);
}

.dark-mode .about-me-text,
.dark-mode .btn,
.dark-mode .project-text,
.dark-mode a,
.dark-mode p {
  color: rgb(193, 193, 193);
}

.dark-mode .section__text__p1 {
  transform: scale(1.25);
}

.dark-mode .section__text__p1,
.dark-mode .section__text__p2 {
  color: rgb(187, 187, 187);
}
.dark-mode .experience-sub-title {
  filter: invert(1);
}

.dark-mode .iconStyle {
  filter: invert(1);
}

.dark-mode .btn-color-1,
.dark-mode .btn-color-2,
.dark-mode .section-container,
.dark-mode .text-container,
.dark-mode .color-container,
.dark-mode .details-container {
  background-color: rgb(60, 60, 60);
  color: rgba(230, 230, 230, 0.65);
  border-color: rgb(185, 185, 185);
  border-radius: 20px;
  padding: 0.5rem;
}

.dark-mode .about-containers,
.dark-mode .about-me-text {
  margin: 0.5rem;
}

.dark-mode h3 {
  color: rgb(255, 255, 255);
}

.dark-mode .icon:hover {
  color: #00ffff;
}

.dark-mode .LinkedInIcon,
.dark-mode .ExpIcon,
.dark-mode .EducationIcon,
.dark-mode .GithubIcon {
  filter: invert(1);
}

.dark-mode .contact-icon,
.dark-mode .hamburger-icon {
  filter: invert(1) hue-rotate(180deg);
}

.dark-mode .dark-mode .icon span {
  background: linear-gradient(to right, #00ffff 100%, #d40071 0%);
  /* background: linear-gradient(to right, #ff2600 0%, #00d4d0 100%); */
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.btn,
.btn-color-1,
.btn-color-2,
.color-container,
.details-container,
.icon,
.menu-links,
.section-container,
a,
body {
  transition: background-color 0.3s ease 0s, color 0.3s ease 0s,
    filter 0.3s ease 0s, -webkit-filter 0.3s ease 0s;
}
